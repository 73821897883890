<template>
  <div>
    <ed-modelo :intTipoModeloId="280" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdModelo from "@/components/documento/modelo/index";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdModelo },
  mounted() {
  },
  beforeDestroy() {
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
